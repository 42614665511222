import { IconDefinition, IconPrefix, IconName } from '@fortawesome/fontawesome-svg-core'

export const faLoopReturns = {
  prefix: 'custom' as IconPrefix,
  iconName: 'loop-returns' as IconName,
  icon: [
    34,
    34,
    [],
    'e001',
    [
      'M29.477 16.7385C29.477 23.7738 23.7738 29.477 16.7385 29.477C9.70321 29.477 4 23.7738 4 16.7385C4 9.70321 9.70321 4 16.7385 4C23.7738 4 29.477 9.70321 29.477 16.7385Z',
      'M16.5 7C11.2533 7 7 11.2533 7 16.5C7 21.7467 11.2533 26 16.5 26C21.7467 26 26 21.7467 26 16.5C26 11.2533 21.7467 7 16.5 7ZM16.5 25C11.8056 25 8 21.1944 8 16.5C8 11.8056 11.8056 8 16.5 8C21.1944 8 25 11.8056 25 16.5C25 21.1944 21.1944 25 16.5 25Z',
    ],
  ],
} as IconDefinition

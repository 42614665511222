import React, { useState, useEffect, useRef } from 'react'
import { usePost, notify } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { Form, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarcodeRead } from '@fortawesome/pro-duotone-svg-icons'
import { faQrcode } from '@fortawesome/pro-regular-svg-icons'
import { TableRow } from '../../products/styles'
import { TableCell } from '../../../components/component-items/datatable'

export const MoveInventoryPage = () => {
  const [QRCode, setQRCode] = useState('')
  const [location, setLocation] = useState('')
  const [itemID, setItemID] = useState('')
  const [sku, setSKU] = useState('')
  const [image, setImage] = useState('')
  const [oldLocation, setOldLocation] = useState('')
  const [name, setName] = useState('')
  const {
    state: { csrf },
  } = globalState()

  const handleItemRetrieval = async (event: any) => {
    if (event.key !== 'Enter') {
      return
    }

    const reload = false
    const stringify = true
    const sendResponse = false
    await usePost(
      '/api/inventory/inventory-item/retrieve_item_id/',
      { qr_code: QRCode },
      csrf,
      reload,
      stringify,
      sendResponse
    ).then((response) => {
      if (!response.error) {
        if ('itemId' in response) {
          notify({ type: 'success', message: `${response.sku} is ready to be moved` })
          setItemID(response.itemId)
          setImage(response.image)
          setSKU(response.sku)
          setOldLocation(response.location)
          setName(response.name)
          locationRef?.current?.focus()
          locationRef?.current?.select()
        } else {
          notify({ type: 'error', message: 'Unable to retrieve inventory item. Please try again' })
        }
      }
    })
  }

  const handleKeyPressed = (event: any) => {
    if (event.key === 'Enter') {
      handleSubmit(event)
    }
  }

  const handleSubmit = async (event: any) => {
    const reload = false
    const stringify = true
    const sendResponse = false
    await usePost(
      `/api/inventory/inventory-item/${itemID}/location/`,
      { moveTo: location },
      csrf,
      reload,
      stringify,
      sendResponse
    ).then((response) => {
      if (!response.error) {
        notify({ type: 'success', message: 'Inventory item moved successfully' })
        setQRCode('')
        setLocation('')
        setItemID('')
        itemRef?.current?.focus()
        itemRef?.current?.select()
      }
    })
  }

  // Add cursor to input field on render
  const itemRef = useRef<HTMLInputElement>(null)
  const locationRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    itemRef?.current?.focus()
    itemRef?.current?.select()
  }, [])

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>Move Inventory</h3>
        </GlobalStyles.CardTitle>
      </GlobalStyles.CardHeader>
      <div style={{ width: 500, maxWidth: 800, minHeight: 110, margin: '2em auto' }}>
        <Form.Group className="required">
          <Form.Label>Inventory Barcode</Form.Label>
          <InputGroup>
            <Form.Control
              required
              type="text"
              placeholder="Inventory Barcode"
              value={QRCode}
              maxLength={100}
              onChange={(e) => setQRCode(e.target.value)}
              ref={itemRef}
              onKeyDown={handleItemRetrieval}
            />
            <InputGroup.Text
              id="inputGroupPrepend"
              style={{ width: 40, display: 'flex', justifyContent: 'center', borderRadius: '0 5px 5px 0' }}
            >
              <FontAwesomeIcon icon={faQrcode} />
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">Please enter the customers full name.</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        {itemID && (
          <GlobalStyles.DataTable style={{ marginTop: '2em' }}>
            <tbody>
              <tr>
                <th>
                  <span className="text center">Img</span>
                </th>
                <th>
                  <span className="text center">Product</span>
                </th>
                <th>
                  <span className="text center">SKU</span>
                </th>
                <th>
                  <span className="text center">Old Location</span>
                </th>
              </tr>
              <TableRow>
                <TableCell center>
                  <img src={image} style={{ height: 'auto', maxWidth: 100, maxHeight: 50 }} />
                </TableCell>
                <TableCell center>
                  <div>{name}</div>
                </TableCell>
                <TableCell center input={sku} />
                <TableCell center input={oldLocation} />
              </TableRow>
            </tbody>
          </GlobalStyles.DataTable>
        )}
        <Form.Group className="required">
          <Form.Label style={{ marginTop: '2em' }}>Destination Location</Form.Label>
          <InputGroup>
            <Form.Control
              required
              type="text"
              placeholder="Location"
              maxLength={100}
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              ref={locationRef}
              onKeyDown={handleKeyPressed}
            />
            <InputGroup.Text
              id="inputGroupPrepend"
              style={{ width: 40, display: 'flex', justifyContent: 'center', borderRadius: '0 5px 5px 0' }}
            >
              <FontAwesomeIcon icon={faBarcodeRead} />
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">Please add an email.</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <GlobalStyles.Button
          className="royal"
          style={{ marginTop: '2em', float: 'right', minWidth: 125 }}
          onClick={(e) => handleSubmit(e)}
        >
          Submit
        </GlobalStyles.Button>
      </div>
    </GlobalStyles.FullPageCard>
  )
}

import React, { useState, useRef, useEffect, FunctionComponent } from 'react'
import { useClick, usePost } from '../../component-items/helpers'
import { Link, useHistory, useLocation } from 'react-router-dom'

// Components
import styled, { useTheme } from 'styled-components'
import { NotificationMessage, formatDate } from '../../notifications/utils'
import GlobalStyles from '../../component-items/styles'
import { NotificationIcon } from '../../notifications/utils'
import { NotificationProps } from '../../notifications/types'
import { MarkAsReadProps } from './types'
import { Styled } from './styles'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faCheckDouble } from '@fortawesome/pro-duotone-svg-icons'

// Enums
import { faCheck, faDotCircle, faExternalLink } from '@fortawesome/pro-solid-svg-icons'
import { fetchNotifications } from '../../notifications/fetch-notifications'
import { subscribeToNotifications } from '../../notifications/subscribe-to-notifications'
import { globalState } from '../../../store'
import CardLoading from '../../component-items/loading-popover'
import { NoneLeft } from '../../../components/notifications/styles'

export const Notifications = () => {
  const {
    state: { csrf },
  } = globalState()
  const history = useHistory()
  const location = useLocation()
  const theme: any = useTheme()
  const [isOpen, setOpen] = useState(false)
  const [hasNotifications, setHasNotifications] = useState(false)

  const node = useRef(null)
  useClick(node, setOpen)

  // const notifications = TestData
  const [loaded, setLoaded] = useState(false)
  const [notifications, setNotifications] = useState<NotificationProps[]>([])

  useEffect(() => {
    // Fetch notifications for the order on page load
    let url = `/api/core/notifications/user_activity_header/?system_notifications=true`
    fetchNotifications(url).then((result) => {
      setLoaded(true)
      setNotifications(result['results'] ? result.results : result)
    })

    // Subscribe to notifications for the order via websocket
    const unsubscribe = subscribeToNotifications('user', (notification) => {
      // Handle real-time update (e.g., append notification to list)
      setNotifications((prevNotifications) => [notification, ...prevNotifications])
    })

    // Unsubscribe when component unmounts or orderId changes
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    setOpen(false)
  }, [location])

  useEffect(() => {
    if (
      notifications.filter((notification) => notification.requires_acknowledgement && !notification.acknowledged).length
    ) {
      setHasNotifications(true)
    }
  }, [notifications])

  const handleClick = () => {
    setOpen(!isOpen)
    history.push('/notification/')
  }

  const handleMarkAsRead = (id: number) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) => {
        if (notification.id === id && notification.requires_acknowledgement && !notification.acknowledged) {
          usePost(`/api/core/notifications/${notification.id}/acknowledge/`, {}, csrf, false, true, false, false)
          return { ...notification, acknowledged: true }
        }
        return notification
      })
    )
  }

  const handleMarkAllRead = () => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) => {
        if (notification.requires_acknowledgement && !notification.acknowledged) {
          usePost(`/api/core/notifications/${notification.id}/acknowledge/`, {}, csrf, false, true, false, false)
          return { ...notification, acknowledged: true }
        }
        return notification
      })
    )
  }

  return (
    <Styled.Notifications ref={node}>
      <Styled.HeaderButton
        className={isOpen ? 'active' : ''}
        onClick={() => {
          setOpen(!isOpen)
          setHasNotifications(false)
        }}
        aria-haspopup="true"
      >
        <span className={'header__topbar-icon'}>
          <i>
            <FontAwesomeIcon icon={faBell} />
          </i>
        </span>
        {hasNotifications ? <span className="badge-icon"></span> : null}
      </Styled.HeaderButton>
      {isOpen ? (
        <div className="notification__dropdown-menu">
          <form>
            <div className="head">
              <Styled.ExternalLink onClick={handleClick}>
                <FontAwesomeIcon icon={faExternalLink} color="white" />
              </Styled.ExternalLink>
              <h3 className="head__title">User Activity</h3>
              <div className="head__sub">
                <span className="head__desc">
                  <FontAwesomeIcon icon={faDotCircle} color={theme.colors.mySin} style={{ marginRight: '1em' }} />
                  {notifications.length} new notifications
                </span>
              </div>
            </div>
            <Styled.NotificationHeader>
              <h3>LATEST</h3>
              <Styled.NotificationOptions onClick={() => handleMarkAllRead()}>
                <GlobalStyles.Tooltip data-title="Mark all as read">
                  <FontAwesomeIcon icon={faCheckDouble} color="blue" />
                </GlobalStyles.Tooltip>
              </Styled.NotificationOptions>
            </Styled.NotificationHeader>
            <div className="notification-list" style={!notifications.length && loaded ? { display: 'flex' } : {}}>
              {notifications.length ? (
                notifications.map((item, index) => <NotificationItem key={index} {...item} {...{ handleMarkAsRead }} />)
              ) : loaded ? (
                <NoneLeft>
                  <FontAwesomeIcon icon={faBell} />
                  No Activity!
                </NoneLeft>
              ) : null}
            </div>
            {!loaded ? <CardLoading /> : null}
          </form>
        </div>
      ) : null}
    </Styled.Notifications>
  )
}

const NotificationItem: FunctionComponent<NotificationProps & MarkAsReadProps> = ({
  id,
  url,
  message,
  created,
  created_by_fullname,
  notification_type: type,
  recipients_display_names: recipients,
  acknowledged,
  handleMarkAsRead,
}) => {
  const theme: any = useTheme()
  const [hoverRead, setHoverRead] = useState(false)
  const [hoverDetails, setHoverDetails] = useState(false)
  const notificationBody = NotificationMessage({ message, recipients })

  return (
    <div className={`notification__item ${hoverDetails ? 'hover' : !acknowledged ? 'unread' : ''}`}>
      <div className="notification__item-icon">{NotificationIcon({ type })}</div>
      <Link
        to={url}
        className="notification__item-details"
        onMouseEnter={() => setHoverDetails(true)}
        onMouseLeave={() => setHoverDetails(false)}
      >
        <div className="notification__item-title">{notificationBody}</div>
        <div className="notification__item-subtitle" style={{ color: '#000b5b', fontWeight: 500 }}>
          {created_by_fullname}
        </div>
        <div className="notification__item-time">{formatDate(created)}</div>
      </Link>
      <div>
        <Styled.NotificationOptions
          onMouseEnter={() => setHoverRead(true)}
          onMouseLeave={() => setHoverRead(false)}
          className={acknowledged ? 'background-hover-hidden' : ''}
        >
          <FontAwesomeIcon
            icon={faDotCircle}
            color={theme.colors.mySin}
            className={`icon base-icon ${!acknowledged && !hoverRead ? 'visible' : 'hidden'}`}
          />
          <FontAwesomeIcon
            icon={faCheck}
            color={theme.colors.dodgerBlue}
            className={`icon overlay-icon ${!acknowledged && hoverRead ? 'visible' : 'hidden'}`}
            onClick={() => handleMarkAsRead(id)}
          />
        </Styled.NotificationOptions>
      </div>
    </div>
  )
}

import React, { useState, useRef, useEffect, memo } from 'react'
import { useHistory } from 'react-router-dom'
import { toast, TypeOptions } from 'react-toastify'
import { globalState } from '../../store'
import { ConfirmToast } from './ConfirmToast'
import ResizeObserver from 'resize-observer-polyfill'
import { useSpring, a } from '@react-spring/web'
import _ from 'lodash'

import { NotifyProps } from './types'

let toastId = ''

export const notify = ({
  title = '',
  message,
  type = 'success',
  onSubmit = function () {},
  onDismiss,
  requestConfirmation = false,
  autoClose = 5000,
}: NotifyProps) => {
  let customTitle: string = ''
  switch (type) {
    case 'info':
      customTitle = 'Info!'
      break
    case 'success':
      customTitle = 'Success!'
      break
    case 'warning':
      customTitle = 'Warning!'
      break
    case 'error':
      customTitle = 'Error!'
      break
    default:
      customTitle = 'Info!'
  }
  toast(
    () => (
      <ConfirmToast
        title={title || customTitle}
        body={message}
        onSubmit={() => {
          onSubmit()
          toast.dismiss(toastId)
        }}
        onDismiss={() => {
          onDismiss?.()
          toast.dismiss(toastId)
        }}
        requestConfirmation={requestConfirmation}
      />
    ),
    {
      ...(toastId ? { toastId } : {}),
      // @ts-ignore
      type,
      autoClose,
      closeOnClick: !!autoClose,
    }
  )
}

export function isNormalInteger(str: string) {
  var n = Math.floor(Number(str))
  return n !== Infinity && String(n) === str && n >= 0
}

export function usePrevious(value: any) {
  const ref = useRef()
  useEffect(() => void (ref.current = value), [value])
  return ref.current
}

export function useMeasure() {
  const ref = useRef()
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 })
  const [ro] = useState(() => new ResizeObserver(([entry]) => set(entry.contentRect)))
  useEffect(() => {
    if (ref.current) ro.observe(ref.current)
    return () => ro.disconnect()
  }, [])
  return [{ ref }, bounds]
}

export const DropDown = memo(({ children, open }: { children: any; open: boolean }) => {
  const previous = usePrevious(open)
  // @ts-ignore
  const [bind, { height: viewHeight }] = useMeasure()
  const { height, opacity, transform } = useSpring({
    from: { height: 0, opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: { height: open ? viewHeight : 0, opacity: open ? 1 : 0, transform: `translate3d(${open ? 0 : 20}px,0,0)` },
  })
  return (
    <div style={{ overflowX: 'hidden' }}>
      <a.div style={{ opacity, height: open && previous === open ? 'auto' : height }}>
        {/* @ts-ignore */}
        <a.div style={{ transform }} {...bind}>
          {children}
        </a.div>
      </a.div>
    </div>
  )
})
DropDown.displayName = 'DropDown'

// Set intervat when using hooks
export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    // @ts-ignore
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      // @ts-ignore
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const compareObjects = (apiOptions: any) => {
  const myPreviousState = usePrevious(apiOptions)
  const [data, updateData] = useState<any>([])
  useEffect(() => {
    if (myPreviousState && !_.isEqual(myPreviousState, apiOptions)) {
      updateData(apiOptions)
    }
  }, [apiOptions])
  return data
}

// Fetch API using hooks
export const useFetch = (url: string, options: any, timer?: number, requireAuthentication = true) => {
  const options_update = compareObjects(options)
  const [response, setResponse] = useState<any>(null)
  const [placeholder, setPlaceholder] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [reload, setReload] = useState(false)
  const [error, setError] = useState(false)

  let history = useHistory()
  const {
    state: { csrf, isAuthenticated },
    state,
    dispatch,
  } = globalState()

  const time = timer ? timer * 1000 : null
  useInterval(() => {
    if (time) {
      setReload(true)
    }
  }, time)

  useEffect(() => {
    let mounted = true
    if (!timer) {
      setLoaded(false)
    }
    const fetchData = async () => {
      setError(false)
      try {
        const res = await fetch(url, {
          ...options,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf,
          },
        })

        // If the user is unauthenticated the proxy is returning a redirect to login page
        if (res.status == 403 || (res.redirected && res.url.includes('/login?next='))) {
          dispatch({ type: 'logout' })
          var searchParams = new URLSearchParams(window.location.search)
          searchParams.set('next', window.location.pathname)
          history.push(`/login/?${searchParams}`)
          return
        }

        if (res.redirected && options.redirect) {
          window.location.href = res.url
          setLoaded(true)
        } else {
          const json = await res.json()
          if (mounted) {
            setResponse(json)
            setLoaded(true)
            setReload(false)
          }
        }
      } catch (error) {
        setError(true)
      }
    }
    if (!isAuthenticated && requireAuthentication) {
      dispatch({ type: 'logout' })
      var searchParams = new URLSearchParams(window.location.search)
      searchParams.set('next', window.location.pathname)
      history.push(`/login/?${searchParams}`)
    } else if (url) {
      fetchData()
    }
    return () => {
      mounted = false
    }
  }, [isAuthenticated, reload, url, options_update])
  return { response, placeholder, loaded, error }
}

const loader: any = document.querySelector('.pending-container')
export const hideLoader = () => loader.classList.add('loader--hide')
export const showLoader = () => loader.classList.remove('loader--hide')

//Make POST request
export const usePost = async (
  url: string,
  body: any,
  csrf: string,
  reload = true,
  stringify = true,
  return_response = false,
  show_loader = true,
  show_error = true
) => {
  return requestHandler(url, body, csrf, reload, stringify, return_response, show_loader, 'POST', show_error)
}

//Make PUT request
export const usePut = async (
  url: string,
  body: any,
  csrf: string,
  reload = true,
  stringify = true,
  return_response = false,
  show_loader = true,
  show_error = true
) => {
  return requestHandler(url, body, csrf, reload, stringify, return_response, show_loader, 'PUT', show_error)
}

//Make post request
export const requestHandler = async (
  url: string,
  body: any,
  csrf: string,
  reload = true,
  stringify = true,
  return_response = false,
  show_loader = true,
  method = 'POST',
  show_error = true
) => {
  if (!url || !csrf) {
    return {}
  }
  if (show_loader) {
    showLoader()
  }
  // Don't change content-type if body is of FormData
  const contentType = body instanceof FormData ? {} : { 'Content-Type': 'application/json' }
  return await fetch(url, {
    method: method,
    redirect: 'follow',
    // @ts-ignore
    headers: {
      'X-CSRFToken': csrf,
      ...contentType,
    },
    body: stringify ? JSON.stringify(body) : body,
  })
    .then((response) => {
      hideLoader()

      if (!response.ok) {
        if (response.status == 401) {
          var searchParams = new URLSearchParams(window.location.search)
          searchParams.set('next', window.location.pathname)
          // @ts-ignore
          history.push(`/login/?${searchParams}`)
          return
        }
        // Throw error if there was an error connecting eg. 404, 500 etc.
        if (return_response) {
          return response
        }
        throw Error(response.statusText)
      } else if (response.redirected) {
        window.location.href = response.url
        return
      } else if (method === 'PUT' && response.status == 204) {
        if (reload) {
          window.location.reload()
        } else {
          return
        }
      }
      return response.json()
    })
    .then((response) => {
      hideLoader()
      if (!response) {
        return
      }
      // Handle custom errors
      else if (response.custom_errors?.length > 0 && !return_response) {
        /* 
        Example Format:
        {title: 'Orders in Fulfillment', description: 'Cannot update bundle as bundle has orders currently being fulfilled', links: ['Order #1023', 'Order #1025']}
        */
        response.custom_errors.map((error: { title: string; description: string; links: string[] }, i: number) => {
          const custom_errors = (
            <ul style={{ margin: 0, paddingLeft: '2em' }}>
              <li>{error.description}</li>
              {error.links?.length > 0 && (
                <li>
                  Related Links:
                  <ol>
                    {error.links?.map((link, i) => (
                      <li key={i}>
                        <a href={link} target="_blank" rel="noreferrer">
                          {link}
                        </a>
                      </li>
                    ))}
                  </ol>
                </li>
              )}
            </ul>
          )
          notify({
            title: error.title,
            message: custom_errors,
            type: 'error',
            autoClose: undefined,
          })
        })
      }
      // Handle FieldErrors, ignore exit scanning
      else if (response.errors && !return_response && !response.exit_scanned) {
        /* 
        Example Format:
        {'address_1': 'This field cannot exceed 40 characters', 'address_2': This field cannot exceed 10 characters}
        */
        for (const key in response.errors) {
          const errors = (
            <ul style={{ margin: 0, paddingLeft: '2em' }}>
              {response.errors[key]?.length > 0 && (
                <>{response.errors[key]?.map((error: string, i: number) => <li key={i}>{error}</li>)}</>
              )}
            </ul>
          )

          notify({
            title: key.replaceAll('_', ' '),
            message: errors,
            type: 'error',
            autoClose: undefined,
          })
        }
      } else if (response.error && !return_response) {
        // Throw an error if one exists in the json response
        notify({ title: 'Error!', message: response.error, type: 'error' })
      } else if (reload) {
        window.location.reload()
        return
      } else if (response.success && response.message) {
        if (return_response) {
          return response
        } else {
          notify({ title: 'Info!', message: response.message, type: 'info' })
        }
      }
      return response
    })
    .catch((error) => {
      hideLoader()
      notify({ title: 'Error!', message: error, type: 'error' })
    })
}

//Make post request
export const useDelete = async (
  url: string,
  csrf: string,
  reload = true,
  show_loader = true,
  delete_confirmation = true,
  callback: any = null
) => {
  if (!url || !csrf) {
    return {}
  }
  const onDelete = async () => {
    if (show_loader) {
      showLoader()
    }
    // Don't change content-type if body is of FormData
    return await fetch(url, {
      method: 'DELETE',
      redirect: 'follow',
      headers: {
        'X-CSRFToken': csrf,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        hideLoader()
        if (!response.ok) {
          if (response.status == 401) {
            var searchParams = new URLSearchParams(window.location.search)
            searchParams.set('next', window.location.pathname)
            // @ts-ignore
            history.push(`/login/?${searchParams}`)
            return
          }
          // Throw error if there was an error connecting eg. 404, 500 etc.
          notify({ title: String(response.status), message: response.statusText, type: 'error' })
          throw Error(response.statusText)
        } else if (response.redirected) {
          window.location.href = response.url
          return
        } else if (response.status == 204 && reload) {
          window.location.reload
        }

        // Reload non-json responses
        const contentType = response.headers.get('content-type')
        if (reload && !(contentType && contentType.indexOf('application/json') !== -1)) {
          window.location.reload()
        }
        callback && callback(response)
        return response.json()
      })
      .then((response) => {
        hideLoader()
        if (reload && !response.error) {
          window.location.reload()
        } else if (!response) {
          return
        } else if (response.error) {
          //Throw an error if there is an error response in the json response
          notify({ title: 'Error!', message: response.error, type: 'error' })
        } else if (response.success && response.message) {
          notify({ title: 'Error!', message: response.error, type: 'error' })
        }
        return response
      })
      .catch((error) => {
        hideLoader()
        notify({ title: 'Error!', message: error, type: 'error' })
      })
  }
  if (delete_confirmation) {
    notify({
      title: 'Delete!',
      message: 'Are you sure you want to delete this item?',
      type: 'warning',
      onSubmit: onDelete,
      autoClose: undefined,
      requestConfirmation: true,
    })
  } else {
    onDelete()
  }
}

// Close lightbox when click outside of target
export const useClick = (node: any, setOpen: (open: boolean) => void, reverse: boolean = false) => {
  const handleClick = (e: any) => {
    if (!node.current) {
      return
    }
    var containsTarget = node.current.contains(e.target)
    if (containsTarget && !reverse) {
      return
    } else if (!containsTarget && reverse) {
      return
    }
    // outside click
    setOpen(false)
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])
}

export const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth)

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  // Return the width so we can use it in our components
  return { width }
}

export const downloadLoading = async (url: string) => {
  // Step 1: start the fetch and obtain a reader
  let response: any = await fetch(url)

  const reader = response.body.getReader()

  // Step 2: get total length
  const contentLength = +response.headers.get('Content-Length')

  // Step 3: read the data
  let receivedLength = 0 // received that many bytes at the moment
  let chunks = [] // array of received binary chunks (comprises the body)
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { done, value } = await reader.read()

    if (done) {
      break
    }

    chunks.push(value)
    receivedLength += value.length
  }

  // Step 4: concatenate chunks into single Uint8Array
  let chunksAll = new Uint8Array(receivedLength) // (4.1)
  let position = 0
  for (let chunk of chunks) {
    chunksAll.set(chunk, position) // (4.2)
    position += chunk.length
  }

  // Step 5: decode into a string
  let result = new TextDecoder('utf-8').decode(chunksAll)

  // We're done!
  let commits = JSON.parse(result)
  notify({ title: 'Error!', message: commits[0].author.login, type: 'error' })
}

export const StickyElement = ({ className, children }: { className?: string; children: any }) => {
  const navigationHeight = 95
  const stickyNode = useRef<any>(null)
  const [distance, setDistance] = useState<any>(0)
  const [sticky, setSticky] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar)
    return () => {
      window.removeEventListener('scroll', stickNavbar)
    }
  }, [sticky, distance])

  const stickNavbar = () => {
    if (window !== undefined) {
      if (stickyNode.current && distance == 0) {
        setDistance(window.pageYOffset + stickyNode.current?.getBoundingClientRect().top - navigationHeight)
      } else {
        let windowHeight = window.scrollY
        windowHeight > distance ? setSticky(true) : setSticky(false)
      }
    }
  }

  return (
    <div className={className} style={sticky ? { position: 'sticky', top: navigationHeight } : {}} ref={stickyNode}>
      {children}
    </div>
  )
}

import styled, { keyframes } from 'styled-components'
import { GlobalStyled } from '../../component-items/styles'
import { device } from '../../component-items/device'
import { StatusBarStyled } from '../../component-items/status-bar'

const BrandLogo = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-begin;
  left: 20px;
  top: 20px;
  span {
    color: ${(props) => props.theme.colors.white};
    padding-left: 10px;
    font-size: 18px;
    font-weight: 400;
  }
  @media only screen and (max-width: 480px) {
    visibility: hidden;
  }
`

const HeaderMenu = styled.div`
  display: flex;
  align-items: stretch;
  height: 100%;
  margin: 0 0 0 25px;
  .header-menu__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: stretch;
  }
  .header-menu__item {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 1em;
    &:first-child button.menu-button {
      background-color: ${(props) => props.theme.colors.zircon};
    }
    &:not(:first-child) {
      span,
      i {
        color: #6c7293;
      }
    }
  }
  button.menu-button {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 0.65rem 1.1rem;
    transition: all 0.3s ease;
    cursor: pointer;
    border: none;
    span {
      color: ${(props) => props.theme.colors.dodgerBlue};
      font-weight: 500;
    }
    i {
      color: ${(props) => props.theme.colors.dodgerBlue};
      font-size: 0.8rem;
      line-height: 18px;
      width: 20px;
      justify-content: flex-end;
      display: flex;
      align-items: center;
    }
    &:hover,
    &.active {
      background-color: #e9ebf4 !important;
    }
  }
`

const MenuPopup = styled.div`
  user-select: none;
  position: absolute;
  top: 100%;
  border-radius: 4px;
  width: 250px;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  background-color: ${(props) => props.theme.colors.white};
  animation: ${GlobalStyled.moveUp} 0.3s ease 1;
  h3 {
    padding: 30px 30px 10px 30px;
    margin: 0;
    color: #5f6281;
    font-weight: 500;
    font-size: 1.07rem;
    text-transform: initial;
  }
  ul {
    padding: 0;
  }
  ul.menu-list {
    padding-bottom: 20px;
    li,
    span {
      transition: all 0.3s ease !important;
    }
    li:hover {
      background-color: #f2f3f8;
      span {
        color: #2739c1;
      }
    }
  }
  li {
    flex-direction: column;
  }
  li {
    display: flex;
    flex-grow: 1;
    margin: 0;
    a {
      cursor: pointer;
      padding: 11px 30px;
      display: flex;
      align-items: center;
      flex-grow: 1;
      text-decoration: none;
      position: relative;
      i {
        width: 20px;
        display: flex;
        align-items: center;
        line-height: 0;
        span {
          width: 9px;
          height: 1px;
          background-color: #cacad2;
        }
      }
      span {
        font-weight: 400;
        color: #5f6281;
      }
    }
  }
`

const SearchResultItem = styled.a`
  user-select: none;
  cursor: pointer;
  padding: 0.75rem 0;
  display: flex;
  align-items: center;
  .search__item-icon {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 32px;
    height: 32px;
    i {
      font-size: 1.4rem;
    }
    img {
      width: 100%;
      align-self: center;
      height: auto;
    }
    &.exit i {
      color: #900000;
      align-self: center;
    }
  }
  .search__item-wrapper {
    padding-left: 0.75rem;
    .search__item-title {
      color: #48465b;
      font-size: 1rem;
      font-weight: 500;
      transition: all 0.3s !important;
    }
    .search__item-desc {
      font-size: 0.9rem;
      line-height: 1.1;
      font-weight: 400;
      color: #74788d;
    }
  }
  &:hover {
    background: #eee;
    .search__item-title {
      color: ${(props) => props.theme.colors.dodgerBlue};
    }
  }
`

const Search = styled.div`
  .dropdown__menu {
    position: absolute;
    top: 100%;
    z-index: 95;
    display: block;
    width: 320px;
    padding: 1rem 1.5rem;
    background: ${(props) => props.theme.colors.white};
    border-radius: 4px;
    transform: translate3d(-150px, 0px, 0px);
    animation: ${GlobalStyled.moveUp} 0.3s ease 1;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  }
  .input-icon {
    display: flex;
    align-items: center;
    padding: 0.65rem 1rem 0.65rem 0;
  }
  input {
    border: 0;
    flex: 1 1 auto;
    outline: 0 !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
  }
  form {
    padding-bottom: 0.75rem;
    border-bottom: 1px solid #ebedf2;
    .close {
      cursor: pointer;
      padding: 0.65rem 0 0.65rem 1rem;
    }
    .spinner {
      position: relative;
    }
    .spinner::before {
      content: '';
      right: 0;
      border: 2px solid ${(props) => props.theme.colors.dodgerBlue};
      border-right: 2px solid transparent;
      width: 16px;
      height: 16px;
      margin-top: -8px;
      animation: ${GlobalStyled.Spinner} 0.5s linear infinite;
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      border-radius: 50%;
    }
    .input-area {
      display: flex;
    }
  }
  .search__message {
    display: flex;
    padding: 1rem 0;
    font-weight: 500;
    font-size: 1rem;
    color: #646c9a;
    align-items: center;
    span {
      font-size: 3em;
    }
  }
  .search__category {
    font-size: 1rem;
    margin: 0.25rem 0 0.75rem 0;
    font-weight: 600;
    color: #595d6e;
  }
  .search__result {
    height: 325px;
    overflow-y: scroll;
    padding: 1em 0;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bbc2e5;
      border-radius: 5px;
    }
  }
`

const ActAsPopup = styled(Search)`
  .dropdown__menu {
    left: 0;
    transform: none;
  }
`

const HeaderButton = styled.button`
  border: none;
  .header__topbar-icon {
    font-size: 1.5rem;
    transition: all 0.3s !important;
    border-bottom: 1px solid transparent;
    color: #4b5380;
  }
  .header__topbar-user {
    transition: all 0.3s;
    padding: 0 8px;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    height: 50px;
    cursor: pointer;
    transition: all 0.3s !important;
    .header__topbar-username {
      transition: all 0.3s !important;
      color: #6c7293;
      display: flex;
      align-self: center;
      margin: 0 0.55rem 0 0;
      font-weight: 500;
      font-size: 1rem;
      border-bottom: 1px solid transparent;
    }
    img {
      align-self: center;
      height: 38px;
      width: 38px;
      object-fit: cover;
      border-radius: 5px;
    }
  }
  &:hover,
  &.active {
    .header__topbar-username,
    .header__topbar-icon {
      color: ${(props) => props.theme.colors.dodgerBlue};
      border-bottom: 1px solid ${(props) => props.theme.colors.dodgerBlue};
    }
  }
`

const heartbeat = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
    background: #ff862b;
  }
  100% {
    transform: scale(1);
  }
`

export const move = keyframes`
    from { background-position: 0 0; }
    to { background-position: -200px 0; }
`

const Notifications = styled.div`
  .badge-icon {
    position: absolute;
    left: 50%;
    margin-left: -2px;
    top: 18px;
    background: #ff862b;
    display: inline-block;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    animation: ${heartbeat} 1.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 1s infinite;
  }
  .notification__dropdown-menu {
    position: absolute;
    will-change: transform;
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 4px;
    top: 75px;
    left: 0px;
    padding: 0;
    width: 320px;
    transform: translate3d(-140px, 0px, 0px);
    animation: ${GlobalStyled.moveUp} 0.3s ease 1;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  }
  .head {
    position: relative;
    background: #242939 url('/api/static/assets/login/background.png') 10px 10px repeat;
    background-size: 200px;
    border-radius: 4px 4px 0 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 1rem;
    min-height: 100px;
    animation: ${move} 40s linear infinite;
    .head__title {
      font-size: 1.4rem;
      color: ${(props) => props.theme.colors.white};
      margin: 0;
    }
    .head__sub {
      position: absolute;
      display: flex;
      align-items: center;
      height: 2.8rem;
      bottom: -1.4rem;
      span {
        color: #74788d;
        font-size: 1rem;
        font-weight: 500;
        background: ${(props) => props.theme.colors.white};
        text-align: center;
        padding: 0.75rem 1rem;
        box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
        border-radius: 3px;
      }
    }
  }
  .notification-list {
    min-height: 300px;
    max-height: 40vh;
    overflow: scroll;
    margin: 0 0 20px;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bbc2e5;
      border-radius: 5px;
    }
  }
  .notification__item {
    display: flex;
    align-items: center;
    padding: 1.1rem 1.5rem;
    border-bottom: 1px solid #f7f8fa;
    cursor: pointer;
    transition: all 0.3s !important;
    .notification__item-icon {
      display: flex;
      flex: 0 0 2.5rem;
      align-items: center;
      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    .notification__item-details {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
    .notification__item-title {
      font-size: 1.1rem;
      font-weight: 400;
      color: #595d6e;
    }
    .notification__item-time {
      font-size: 1rem;
      font-weight: 400;
      color: #74788d;
    }
    &.unread {
      background-color: #f4f5fb;
    }
    &.hover {
      background: ${(props) => props.theme.colors.gallery};
    }
  }
`

const UserBar = styled.div`
  .user__dropdown-menu {
    position: absolute;
    border-radius: 4px;
    animation: ${GlobalStyled.moveUp} 0.3s ease 1;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    will-change: transform;
    width: 260px;
    background: ${(props) => props.theme.colors.white};
    top: 75px;
    right: 0px;
    ul {
      display: block;
      padding: 0.5rem 0;
      margin: 0;
      list-style: none;
      border: 0;
    }
    .user-card {
      background-color: #404a67;
      border-radius: 4px 4px 0 0;
      position: relative;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem 1rem;
      min-height: 65px;
      margin-bottom: 40px !important;
    }
    .user-card__wrapper {
      position: absolute;
      display: flex;
      align-items: stretch;
      height: 100%;
      bottom: -32.5px;
      padding: 0 1.25rem;
    }
    .user-card__pic {
      display: flex;
      align-items: center;
      padding-right: 1rem;
      img {
        max-height: 55px;
        max-width: 55px;
        border-radius: 4px;
      }
      .photo-placeholder {
        height: 55px;
        width: 55px;
        i {
          font-size: 2.3rem;
        }
      }
    }
    .user-card__details {
      display: flex;
      flex-direction: column;
      .user-card__name {
        display: flex;
        align-items: flex-end;
        font-size: 1.2rem;
        height: 50%;
        font-weight: 500;
        padding-bottom: 0.25rem;
        color: ${(props) => props.theme.colors.white};
      }
      .user-card__position {
        padding-top: 0.25rem;
        display: flex;
        height: 50%;
        align-items: flex-start;
        font-size: 0.9rem;
        font-weight: 400;
        color: #74788d;
      }
    }
  }
  .nav__separator {
    margin: 0.7rem 0;
    height: 0;
    display: block;
    border-bottom: 1px solid #ebedf2;
  }
  .nav__button {
    display: flex;
    padding: 0.55rem 1.75rem;
    align-items: center;
    .logout {
      background-color: rgba(93, 120, 255, 0.1);
      color: ${(props) => props.theme.colors.dodgerBlue};
      cursor: pointer !important;
      padding: 0.5rem 1rem;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.5;
      border-radius: 0.2rem;
      display: inline-block;
      transition: all 0.3s !important;
      &:hover {
        background-color: ${(props) => props.theme.colors.dodgerBlue};
        color: ${(props) => props.theme.colors.white};
      }
    }
  }
  .photo-placeholder {
    background-color: ${(props) => props.theme.colors.gallery};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    width: 38px;
    border-radius: 5px;
    i {
      color: #060058;
      font-size: 1.8rem;
    }
  }
`

const NavItem = styled.li`
  a {
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding: 0.55rem 1.75rem;
    cursor: pointer;
    transition: all 0.3s !important;
    &:hover {
      background-color: ${(props) => props.theme.colors.gallery};
    }
  }
  .nav__link-icon {
    flex: 0 0 30px;
    font-size: 1.25rem;
    line-height: 0;
    color: #4b5380;
  }
  .nav__link-text {
    flex-grow: 1;
    font-size: 1rem;
    color: #595d6e;
    font-weight: 400;
  }
`

const HeaderTopbarItem = styled.div<{ width?: string | undefined }>`
  display: flex;
  position: relative;
  align-items: stretch;
  margin: 0 0.1rem;
  .header__topbar__container {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    width: ${(props) => (props.width ? props.width : '50px')};
    height: 50px;
  }
`

const Subheader = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px 25px 0;
  align-items: stretch;
  justify-content: space-between;
  .subheader__main {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 15px;
    .subheader__title {
      margin: 0;
      padding: 0 1.5rem 0 0;
      font-size: 1.3rem;
      font-weight: 500;
      color: #595d6e;
    }
    .subheader__breadcrumbs {
      display: flex;
      align-items: center;
      list-style-type: none;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
    }
    .subheader__breadcrumbs-separator {
      display: flex;
      align-items: center;
      padding: 0 0.45rem 0 0;
      &:after {
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        content: ' ';
        background: rgba(149, 156, 182, 0.5);
      }
    }
    .breadcrumbs__separator {
      padding-right: 0.65rem;
    }
    a:first-child {
      padding: 0 0.65rem 0 0;
    }
    a {
      padding: 0 0.35rem 0 0;
      font-size: 1rem;
      font-weight: 500;
      color: #959cb6;
      transition: all 0.3s;
      &:hover {
        color: ${(props) => props.theme.colors.dodgerBlue};
      }
    }
  }
  .subheader__toolbar {
    display: flex;
    user-select: none;
    justify-content: flex-end;
    position: relative;
    margin: 0 0 15px auto;
    .subheader__dropdown-menu {
      position: absolute;
      z-index: 100;
      top: 110%;
      right: 0px;
      width: 240px;
      will-change: transform;
      box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
      padding: 1rem 0;
      border-radius: 4px;
      background-color: ${(props) => props.theme.colors.white};
      ul {
        display: block;
        padding: 0.5rem 0;
        margin: 0;
        list-style: none;
        border: 0;
      }
    }
    .button {
      cursor: pointer;
      margin-left: 0.5rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.2rem;
    }
    .subheader__button {
      width: 34px !important;
      height: 34px !important;
      padding-top: 0;
      padding-bottom: 0;
      background-color: rgba(93, 120, 255, 0.1);
      color: ${(props) => props.theme.colors.dodgerBlue};
      &:hover {
        background-color: ${(props) => props.theme.colors.dodgerBlue};
        color: ${(props) => props.theme.colors.white};
      }
      i {
        padding: 0;
        margin: 0;
      }
    }
    .subheader__button-date {
      box-shadow: 0px 4px 16px 0px rgba(93, 120, 255, 0.15);
      height: 34px !important;
      background-color: ${(props) => props.theme.colors.dodgerBlue};
      color: ${(props) => props.theme.colors.white};
      padding: 0.5rem 1rem;
      font-size: 0.875rem;
      line-height: 1.5;
      &:hover {
        box-shadow: 0px 6px 16px 0px rgba(93, 120, 255, 0.25) !important;
        background-color: ${(props) => props.theme.colors.dodgerBlueLight};
      }
    }
    .time-frame {
      opacity: 0.7;
    }
    .date {
      font-weight: 500 !important;
      padding-right: 0.5em;
    }
  }
`

const SubNavItem = styled.li`
  a {
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding: 0.55rem 1.75rem;
    cursor: pointer;
    transition: all 0.3s !important;
  }
  i {
    flex: 0 0 30px;
    font-size: 1.25rem;
    line-height: 0;
    color: #a2a5b9;
    transition: all 0.3s !important;
  }
  .nav__link-text {
    flex-grow: 1;
    font-size: 1rem;
    color: #595d6e;
    font-weight: 400;
    transition: all 0.3s !important;
  }
  &:hover a {
    background-color: #f7f8fa;
    .nav__link-text,
    i {
      color: ${(props) => props.theme.colors.dodgerBlue};
    }
  }
`

const Header = styled.div<{ navOpen?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  left: ${(props) => (props.navOpen ? '260px' : '80px')};
  z-index: 97;
  display: none;
  justify-content: space-between;
  height: 75px;
  transition: all 0.3s ease;
  background-color: ${(props) => props.theme.colors.white};
  @media ${device.laptop} {
    display: flex;
  }
  &.staging,
  &.localhost {
    background-color: ${(props) => props.theme.colors.periwinkleGray};
  }
  &.beta {
    background-color: ${(props) => props.theme.colors.mySin};
  }

  &.act-as__active {
    background-color: #242939;
    border-bottom: 1px solid #eff0f6;
    .header-menu__item:not(:first-child) button {
      span,
      i {
        transition: all 0.3s ease;
        color: ${(props) => props.theme.colors.white};
      }
      &:hover,
      &.active {
        span,
        i {
          color: #6c7293;
        }
      }
    }
    .header__topbar-username {
      color: #b4b8ce;
    }
    .header__topbar-icon {
      color: #e7ebff;
    }
    .acting-as {
      font-weight: 600;
      padding-left: 0.5em;
    }
  }
  .header__topbar {
    display: flex;
    align-items: stretch;
    padding: 0;
    padding-right: 15px;
  }
`

const MobileHeader = styled(Header)`
  display: flex;
  top: 0;
  left: 0;
  background-color: #242939;
  @media ${device.laptop} {
    display: none;
  }
`

const MobileSubHeader = styled(Header)`
  display: flex;
  top: 75px;
  left: 0;
  border-bottom: 1px solid #dadce7;
  @media ${device.laptop} {
    display: none;
  }
`

const TableRow = styled(GlobalStyled.TableRow)`
  p {
    max-width: fit-content;
    margin-bottom: 0;
  }
`

const NotificationOptions = styled.i`
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  color: #645ca1;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
  &:hover.background-hover-hidden {
    background-color: transparent;
  }
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.5s ease;
  }

  .visible {
    opacity: 1;
  }
  .hidden {
    opacity: 0;
  }
`

const ExternalLink = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  border: 1.5px solid #fefdfe;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #24293a;
  transition: background-color 0.3s ease;
  cursor: pointer;
  &:hover {
    background-color: #97a1c8;
  }
`

const NotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3em 1em 0px;
  padding-bottom: 10px;
  padding-right: 0.75em;
  border-bottom: 1px solid #e5e5e5;
  h3 {
    font-size: 1.2em;
    margin: 0;
  }
`

const HeaderHost = styled(StatusBarStyled)`
  background-color: #810080;
  margin-top: 15px;
  @media ${device.laptop} {
    margin: 19px 5px;
  }
  padding: 1em;
  h3 {
    @media ${device.laptop} {
      margin-top: 8px;
    }
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
  }
`

export const Styled = {
  BrandLogo,
  HeaderMenu,
  MenuPopup,
  ActAsPopup,
  SearchResultItem,
  Search,
  HeaderButton,
  Notifications,
  UserBar,
  NavItem,
  HeaderTopbarItem,
  Subheader,
  SubNavItem,
  Header,
  MobileHeader,
  MobileSubHeader,
  TableRow,
  NotificationOptions,
  ExternalLink,
  NotificationHeader,
  HeaderHost,
}

import React, { useState, useEffect, useRef } from 'react'
import { globalState } from '../../../store'
import { usePost, usePut, useFetch } from '../../../components/component-items/helpers'
import { BackgroundLayer } from './styles'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { Form, Col, InputGroup, Row } from 'react-bootstrap'
import CardLoading from '../../../components/component-items/loading-popover'
import { StatusBoxStyled } from '../../../components/component-items/status-box'
import { StatusBarStyled } from '../../../components/component-items/status-bar'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDollarSign,
  faPercent,
  faCompressArrowsAlt,
  faInfoSquare,
  faExclamationTriangle,
} from '@fortawesome/pro-duotone-svg-icons'

export const PricingTier = () => {
  const {
    state: { csrf, actAs, userIsWarehouse },
  } = globalState()

  const node = useRef<HTMLFormElement>(null)
  const [validated, setValidated] = useState(false)
  const [state, setState] = useState<any>({})

  const { response: resp, error, loaded, placeholder }: any = useFetch(`/api/billing/tier/`, {})
  const [tierName, setTierName] = useState('')

  useEffect(() => {
    if (loaded && resp.tier) {
      setTierName(resp.tier.name)
      setState(resp.tier)
    }
  }, [loaded])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }

    if (!userIsWarehouse) {
      return
    }

    if (!confirm(`Are you sure you want to update the ${tierName} Pricing?`)) {
      return
    }
    usePut(`/api/billing/tier/`, { ...state }, csrf)
  }

  return (
    <BackgroundLayer style={{ display: 'block', position: 'relative', padding: '1em' }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>{tierName} Pricing </h3>
        </GlobalStyles.CardTitle>
        {userIsWarehouse && (
          <GlobalStyles.CardToolbar>
            {/* @ts-ignore */}
            <GlobalStyles.Button onClick={handleSubmit} style={{ marginRight: '-0.5em' }}>
              Update
            </GlobalStyles.Button>
          </GlobalStyles.CardToolbar>
        )}
      </GlobalStyles.CardHeader>
      <Form noValidate ref={node} validated={validated} style={{ margin: 'auto', maxWidth: 900 }}>
        <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
          <GlobalStyles.CardTitle>
            <h3>Processing</h3>
          </GlobalStyles.CardTitle>
        </GlobalStyles.CardHeader>
        {userIsWarehouse && !actAs && (
          <StatusBarStyled style={{ backgroundColor: '#f4f7ff', marginTop: 15 }}>
            <div className="header-item">
              <div className="status-icon">
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#ffb822' }} />
              </div>
              <div className="status-text">
                <strong>Update Warning</strong>: You are editing ShippingTree's Default Pricing Tier
              </div>
            </div>
          </StatusBarStyled>
        )}
        <Row style={{ marginTop: '1em' }}>
          <Form.Group as={Col} lg="4" className="required">
            <Form.Label>Processing</Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faDollarSign} />
              </InputGroup.Text>
              <Form.Control
                disabled={!userIsWarehouse}
                required
                type="number"
                placeholder="Processing"
                value={state.packing_fees || ''}
                onChange={(e) => setState({ ...state, packing_fees: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} lg="4" className="required">
            <Form.Label>Pick 2 - 6</Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faDollarSign} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Pick 2 - 6"
                disabled={!userIsWarehouse}
                required
                value={state.pick_2_6 || ''}
                onChange={(e) => setState({ ...state, pick_2_6: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} lg="4" className="required">
            <Form.Label>Pick 7+</Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faDollarSign} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Pick 7+"
                value={state.pick_7_plus || ''}
                onChange={(e) => setState({ ...state, pick_7_plus: e.target.value })}
                disabled={!userIsWarehouse}
                required
              />
              <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} lg="4" className="required">
            <Form.Label>
              Processing - <strong style={{ fontWeight: 500 }}>Pallet</strong>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faDollarSign} />
              </InputGroup.Text>
              <Form.Control
                disabled={!userIsWarehouse}
                required
                type="number"
                placeholder="Processing"
                value={state.packing_fees_pallet || ''}
                onChange={(e) => setState({ ...state, packing_fees_pallet: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} lg="4" className="required">
            <Form.Label>
              Pick 2 - 6 - <strong style={{ fontWeight: 500 }}>Pallet</strong>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faDollarSign} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Pick 2 - 6"
                disabled={!userIsWarehouse}
                required
                value={state.pick_2_6_case_pack_pallet || ''}
                onChange={(e) => setState({ ...state, pick_2_6_case_pack_pallet: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} lg="4" className="required">
            <Form.Label>
              Pick 7+ - <strong style={{ fontWeight: 500 }}>Pallet</strong>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faDollarSign} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Pick 7+"
                value={state.pick_7_plus_case_pack_pallet || ''}
                onChange={(e) => setState({ ...state, pick_7_plus_case_pack_pallet: e.target.value })}
                disabled={!userIsWarehouse}
                required
              />
              <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} lg="4" className="required">
            <Form.Label>
              Processing - <strong style={{ fontWeight: 500 }}>Master Pack</strong>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faDollarSign} />
              </InputGroup.Text>
              <Form.Control
                disabled={!userIsWarehouse}
                required
                type="number"
                placeholder="Processing"
                value={state.packing_fees_master || ''}
                onChange={(e) => setState({ ...state, packing_fees_master: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} lg="4" className="required">
            <Form.Label>
              Pick 2 - 6 - <strong style={{ fontWeight: 500 }}>Master Pack</strong>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faDollarSign} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Pick 2 - 6"
                disabled={!userIsWarehouse}
                required
                value={state.pick_2_6_case_pack_master || ''}
                onChange={(e) => setState({ ...state, pick_2_6_case_pack_master: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} lg="4" className="required">
            <Form.Label>
              Pick 7+ - <strong style={{ fontWeight: 500 }}>Master Pack</strong>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faDollarSign} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Pick 7+"
                value={state.pick_7_plus_case_pack_master || ''}
                onChange={(e) => setState({ ...state, pick_7_plus_case_pack_master: e.target.value })}
                disabled={!userIsWarehouse}
                required
              />
              <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} lg="4" className="required">
            <Form.Label>
              Processing - <strong style={{ fontWeight: 500 }}>Inner Pack</strong>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faDollarSign} />
              </InputGroup.Text>
              <Form.Control
                disabled={!userIsWarehouse}
                required
                type="number"
                placeholder="Processing"
                value={state.packing_fees_inner || ''}
                onChange={(e) => setState({ ...state, packing_fees_inner: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} lg="4" className="required">
            <Form.Label>
              Pick 2 - 6 - <strong style={{ fontWeight: 500 }}>Inner Pack</strong>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faDollarSign} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Pick 2 - 6"
                disabled={!userIsWarehouse}
                required
                value={state.pick_2_6_case_pack_inner || ''}
                onChange={(e) => setState({ ...state, pick_2_6_case_pack_inner: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} lg="4" className="required">
            <Form.Label>
              Pick 7+ - <strong style={{ fontWeight: 500 }}>Inner Pack</strong>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faDollarSign} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Pick 7+"
                value={state.pick_7_plus_case_pack_inner || ''}
                onChange={(e) => setState({ ...state, pick_7_plus_case_pack_inner: e.target.value })}
                disabled={!userIsWarehouse}
                required
              />
              <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Row>
        {userIsWarehouse && (
          <>
            <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
              <GlobalStyles.CardTitle>
                <h3>Postage</h3>
              </GlobalStyles.CardTitle>
            </GlobalStyles.CardHeader>
            <Row style={{ marginTop: '1em' }}>
              <Form.Group as={Col} lg="4" className="required">
                <Form.Label>
                  <strong style={{ fontWeight: 500 }}>USPS</strong> $ markup
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text id="inputGroupPrepend">
                    <FontAwesomeIcon icon={faDollarSign} />
                  </InputGroup.Text>
                  <Form.Control
                    disabled={!userIsWarehouse}
                    required
                    type="number"
                    placeholder="USPS $ Markup"
                    value={state.postage_literal_markup || ''}
                    onChange={(e) => setState({ ...state, postage_literal_markup: e.target.value })}
                  />
                  <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} lg="4" className="required">
                <Form.Label>
                  <strong style={{ fontWeight: 500 }}>UPS</strong> % markup
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text id="inputGroupPrepend">
                    <FontAwesomeIcon icon={faPercent} />
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    placeholder="UPS % Markup"
                    disabled={!userIsWarehouse}
                    required
                    value={state.ups_percentage_markup || ''}
                    onChange={(e) => setState({ ...state, ups_percentage_markup: e.target.value })}
                  />
                  <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} lg="4" className="required">
                <Form.Label>
                  <strong style={{ fontWeight: 500 }}>FedEx</strong> % markup
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text id="inputGroupPrepend">
                    <FontAwesomeIcon icon={faPercent} />
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    placeholder="FedEx % markup"
                    value={state.fedex_percentage_markup || ''}
                    onChange={(e) => setState({ ...state, fedex_percentage_markup: e.target.value })}
                    disabled={!userIsWarehouse}
                    required
                  />
                  <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} lg="4" className="required">
                <Form.Label>
                  <strong style={{ fontWeight: 500 }}>DHL Express</strong> % markup
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text id="inputGroupPrepend">
                    <FontAwesomeIcon icon={faPercent} />
                  </InputGroup.Text>
                  <Form.Control
                    disabled={!userIsWarehouse}
                    required
                    type="number"
                    placeholder="DHL Express % markup"
                    value={state.dhl_express_percentage_markup || ''}
                    onChange={(e) => setState({ ...state, dhl_express_percentage_markup: e.target.value })}
                  />
                  <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} lg="4" className="required">
                <Form.Label>
                  <strong style={{ fontWeight: 500 }}>DHL Ecomm</strong> % markup
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text id="inputGroupPrepend">
                    <FontAwesomeIcon icon={faPercent} />
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    placeholder="DHL Ecomm % markup"
                    disabled={!userIsWarehouse}
                    required
                    value={state.dhl_ecomm_percentage_markup || ''}
                    onChange={(e) => setState({ ...state, dhl_ecomm_percentage_markup: e.target.value })}
                  />
                  <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} lg="4" className="required">
                <Form.Label>
                  <strong style={{ fontWeight: 500 }}>DHL Ecomm</strong> rel markup
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text id="inputGroupPrepend">
                    <FontAwesomeIcon icon={faCompressArrowsAlt} />
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    placeholder="DHL Ecomm relative markup"
                    value={state.dhl_ecomm_relative_markup || ''}
                    onChange={(e) => setState({ ...state, dhl_ecomm_relative_markup: e.target.value })}
                    disabled={!userIsWarehouse}
                    required
                  />
                  <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} lg="4" className="required">
                <Form.Label>
                  <strong style={{ fontWeight: 500 }}>Passport</strong> % markup
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text id="inputGroupPrepend">
                    <FontAwesomeIcon icon={faPercent} />
                  </InputGroup.Text>
                  <Form.Control
                    disabled={!userIsWarehouse}
                    required
                    type="number"
                    placeholder="Passport % markup"
                    value={state.passport_percentage_markup || ''}
                    onChange={(e) => setState({ ...state, passport_percentage_markup: e.target.value })}
                  />
                  <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} lg="4" className="required">
                <Form.Label>
                  <strong style={{ fontWeight: 500 }}>LaserShip</strong> % markup
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text id="inputGroupPrepend">
                    <FontAwesomeIcon icon={faPercent} />
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    placeholder="LaserShip % markup"
                    disabled={!userIsWarehouse}
                    required
                    value={state.lasership_percentage_markup || ''}
                    onChange={(e) => setState({ ...state, lasership_percentage_markup: e.target.value })}
                  />
                  <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} lg="4" className="required">
                <Form.Label>
                  <strong style={{ fontWeight: 500 }}>LaserShip</strong> rel % markup
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text id="inputGroupPrepend">
                    <FontAwesomeIcon icon={faCompressArrowsAlt} />
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    placeholder="LaserShip relative % markup"
                    value={state.lasership_relative_percentage_markup || ''}
                    onChange={(e) => setState({ ...state, lasership_relative_percentage_markup: e.target.value })}
                    disabled={!userIsWarehouse}
                    required
                  />
                  <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} lg="4" className="required">
                <Form.Label>
                  <strong style={{ fontWeight: 500 }}>Freight</strong> % markup
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text id="inputGroupPrepend">
                    <FontAwesomeIcon icon={faPercent} />
                  </InputGroup.Text>
                  <Form.Control
                    disabled={!userIsWarehouse}
                    required
                    type="number"
                    placeholder="Freight % markup"
                    value={state.freight_percentage_markup || ''}
                    onChange={(e) => setState({ ...state, freight_percentage_markup: e.target.value })}
                  />
                  <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Row>
            <StatusBarStyled style={{ backgroundColor: '#f4f7ff', marginBottom: 0 }}>
              <div className="header-item">
                <div className="status-icon">
                  <FontAwesomeIcon icon={faInfoSquare} style={{ color: '#800080' }} />
                </div>
                <div className="status-text">
                  <strong style={{ fontWeight: 500 }}>Note</strong>: The tier for{' '}
                  <StatusBoxStyled className="purple" style={{ display: 'inline-block', padding: '0 1em' }}>
                    DHL eComm Rel Markup
                  </StatusBoxStyled>{' '}
                  is used as a percentage off of the comparable USPS rate. For instance - if set at 5%, then the DHL
                  Markup will adjust the total for each rate so that it ends up being 5% cheaper than USPS at the same
                  service level. Similarly, the tier for{' '}
                  <StatusBoxStyled className="purple" style={{ display: 'inline-block', padding: '0 1em' }}>
                    LaserShip Rel Markup
                  </StatusBoxStyled>{' '}
                  is a percentage off of the next cheapest rate available.
                </div>
              </div>
            </StatusBarStyled>
          </>
        )}
        <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
          <GlobalStyles.CardTitle>
            <h3>Storage</h3>
          </GlobalStyles.CardTitle>
        </GlobalStyles.CardHeader>
        <Row style={{ marginTop: '1em' }}>
          <Form.Group as={Col} lg="6" className="required">
            <Form.Label>
              Storage / ft<sup>3</sup>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faDollarSign} />
              </InputGroup.Text>
              <Form.Control
                disabled={!userIsWarehouse}
                required
                type="number"
                placeholder="Storage"
                value={state.storage_per_cubic_foot || ''}
                onChange={(e) => setState({ ...state, storage_per_cubic_foot: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please enter a number</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} lg="6" className="required">
            <Form.Label>Minimum Storage Fee</Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faDollarSign} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Minimum Storage Fee"
                value={state.storage_monthly_minimum || ''}
                onChange={(e) => setState({ ...state, storage_monthly_minimum: e.target.value })}
                disabled={!userIsWarehouse}
                required
              />
              <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} lg="4" className="required">
            <Form.Label>
              6 Month Long Term Storage / ft<sup>3</sup>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faDollarSign} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="6 Month Long Term Storage"
                disabled={!userIsWarehouse}
                required
                value={state.lts_6_months || ''}
                onChange={(e) => setState({ ...state, lts_6_months: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} lg="4" className="required">
            <Form.Label>
              9 Month Long Term Storage / ft<sup>3</sup>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faDollarSign} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="9 Month Long Term Storage"
                disabled={!userIsWarehouse}
                required
                value={state.lts_9_months || ''}
                onChange={(e) => setState({ ...state, lts_9_months: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} lg="4" className="required">
            <Form.Label>
              12 Month Long Term Storage / ft<sup>3</sup>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faDollarSign} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="12 Month Long Term Storage"
                disabled={!userIsWarehouse}
                required
                value={state.lts_12_months || ''}
                onChange={(e) => setState({ ...state, lts_12_months: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Row>
        <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
          <GlobalStyles.CardTitle>
            <h3>Other Fees</h3>
          </GlobalStyles.CardTitle>
        </GlobalStyles.CardHeader>
        <Row style={{ marginTop: '1em' }}>
          <Form.Group as={Col} lg="4" className="required">
            <Form.Label>Promo Items</Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faDollarSign} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Promo Items"
                disabled={!userIsWarehouse}
                required
                value={state.promo_items || ''}
                onChange={(e) => setState({ ...state, promo_items: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} lg="4" className="required">
            <Form.Label>Pause Order Fee</Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faDollarSign} />
              </InputGroup.Text>
              <Form.Control
                disabled={!userIsWarehouse}
                required
                type="number"
                placeholder="Pause Order Fee"
                value={state.pause_order_fee || ''}
                onChange={(e) => setState({ ...state, pause_order_fee: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please enter a number</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} lg="4" className="required">
            <Form.Label>Order Cancellation</Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faDollarSign} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Order Cancellation"
                value={state.order_cancellation_fee || ''}
                onChange={(e) => setState({ ...state, order_cancellation_fee: e.target.value })}
                disabled={!userIsWarehouse}
                required
              />
              <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} lg="4" className="required">
            <Form.Label>Serial Number Collection</Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faDollarSign} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Serial Number Collection"
                value={state.sn_collection_fee || ''}
                onChange={(e) => setState({ ...state, sn_collection_fee: e.target.value })}
                disabled={!userIsWarehouse}
                required
              />
              <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} lg="4" className="required">
            <Form.Label>Label Printing</Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faDollarSign} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Label Printing"
                value={state.label_printing || ''}
                onChange={(e) => setState({ ...state, label_printing: e.target.value })}
                disabled={!userIsWarehouse}
                required
              />
              <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} lg="4" className="required">
            <Form.Label>Document Printing</Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faDollarSign} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Document Printing"
                value={state.document_printing || ''}
                onChange={(e) => setState({ ...state, document_printing: e.target.value })}
                disabled={!userIsWarehouse}
                required
              />
              <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} lg="4" className="required">
            <Form.Label>Credit Card Processing Fee %</Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faPercent} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Credit Card Processing Fee %"
                value={state.credit_card_processing_fee_percent || ''}
                onChange={(e) => setState({ ...state, credit_card_processing_fee_percent: e.target.value })}
                disabled={!userIsWarehouse}
                required
              />
              <Form.Control.Feedback type="invalid">Please enter a number.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Row>
      </Form>

      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </BackgroundLayer>
  )
}

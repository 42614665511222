import React, { useState, useRef, useEffect } from 'react'
import { globalState } from '../../../store'

import styled from 'styled-components'
import { usePost } from '../../../components/component-items/helpers'

// Components
import GenericModal from '../../../components/component-items/modal'
import GlobalStyles from '../../../components/component-items/styles'
import { Form } from 'react-bootstrap'

import { MakePaymentModalProps } from './types'

const Label = styled(Form.Label)`
  margin-top: 1em;
`

export const MakePaymentModal = ({ rechargeAmount, paymentMethods, primary, open, setOpen }: MakePaymentModalProps) => {
  const node = useRef<HTMLFormElement>(null)
  const [validated, setValidated] = useState(false)
  const {
    state: { csrf },
  } = globalState()

  const [amount, setAmount] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')

  useEffect(() => {
    setAmount(rechargeAmount)
    if (primary.id) {
      setPaymentMethod(primary.id)
    } else if (paymentMethods.length) {
      setPaymentMethod(paymentMethods[0].id)
    }
  }, [rechargeAmount])

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }
    await usePost('/api/billing/charge/', { ...{ amount, paymentMethod } }, csrf)
  }

  return (
    <GenericModal
      heading={'Make Payment to ShippingTree'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button style={{ minWidth: 125 }} onClick={handleSubmit}>
          Make Payment
        </GlobalStyles.Button>
      }
    >
      <Form noValidate ref={node} validated={validated} onSubmit={handleSubmit} style={{ margin: '0 2em 1em' }}>
        <Label>Amount</Label>
        <Form.Control type="number" value={amount} onChange={(e) => setAmount(e.target.value)} required />
        <Label>Payment Method</Label>
        <Form.Select value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)} required>
          {paymentMethods.map((p, id) => (
            <option value={p.id} key={id}>
              {p.card_type === 'MC' ? 'Mastercard' : p.card_type === 'VI' ? 'Visa' : ''}
              {p.ach_id
                ? ` ${p.last_digits} - ACH`
                : ` ${p.last_digits}, ${p.billing_name} - ${p.exp_month}/${p.exp_year}`}
            </option>
          ))}
        </Form.Select>
      </Form>
    </GenericModal>
  )
}

import React, { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import { useFetch, usePost, usePut, useDelete, notify } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { Row, Col, Form } from 'react-bootstrap'
import CardLoading from '../../../components/component-items/loading-popover'
import { StatusLoopHeader } from './StatusLoopHeader'

// Styles
import { Logo } from './styles'

export const LoopIntegrationPage = () => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const {
    state: { csrf },
  } = globalState()
  const { response: resp, loaded, error, placeholder }: any = useFetch(`/api/loop/`, {})
  const [currentStep, setCurrentStep] = useState(0)
  const [apiKey, setApiKey]: any = useState()
  const [company, setCompany]: any = useState()
  const [settings, setSettings]: any = useState()

  useEffect(() => {
    if (resp?.company) {
      setSettings(resp)
    }
  }, [loaded])

  useEffect(() => {
    if (settings?.company) {
      setCurrentStep(1)
      setApiKey(settings?.api_key)
      setCompany(settings?.company)
    } else {
      setCurrentStep(0)
      setApiKey()
      setCompany()
    }
  }, [settings])

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (!company) {
      const response: any = await usePost(
        `/api/loop/`,
        {
          api_key: apiKey,
        },
        csrf,
        false,
        true,
        true
      )
      if (response) {
        setSettings(response)
        notify({ type: 'success', message: 'Successfully deleted Loop settings' })
      }
    } else {
      const response: any = await usePut(
        `/api/loop/${settings?.id}/`,
        {
          api_key: apiKey,
        },
        csrf,
        false,
        true,
        true
      )
      if (response) {
        notify({ type: 'success', message: 'Successfully deleted Loop settings' })
      }
    }
  }

  const handleRemove = async (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    await useDelete(`/api/loop/${settings?.id}/`, csrf, false, false, false)
    notify({ type: 'success', message: 'Successfully deleted Loop settings' })
    setSettings()
    setApiKey()
    if (null !== inputRef?.current) {
      inputRef.current.value = ''
    }
  }

  const onClickCopy = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    const el = document.createElement('textarea')
    el.value = e.currentTarget.getAttribute('href')
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    notify({ type: 'success', message: 'Copied to clipboard' })
  }

  return (
    <>
      {loaded ? (
        <>
          <StatusLoopHeader settings={settings} />
          <GlobalStyles.FullPageCard style={{ minHeight: 300, padding: '2em' }}>
            <Row className="justify-content-md-center" style={{ margin: '1em 6em' }}>
              <Col md="6" className="align-items-md-center" style={{ textAlign: 'center' }}>
                <Logo src={'/loop.svg'} />
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formApiKey">
                    <Form.Label>API Key</Form.Label>
                    <Form.Control
                      ref={inputRef}
                      type="text"
                      placeholder="Enter API Key"
                      value={apiKey || ''}
                      onChange={(e) => setApiKey(e.target.value)}
                      required
                    />
                    <Form.Control.Feedback type="invalid">Please provide a valid API Key.</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formCompany" className="mt-3">
                    <GlobalStyles.Button className="primary" type="submit">
                      Save
                    </GlobalStyles.Button>
                    {settings && (
                      <GlobalStyles.Button className="secondary" onClick={handleRemove}>
                        Remove
                      </GlobalStyles.Button>
                    )}
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row style={{ margin: '1em 6em' }}>
              <Col>
                <h2>Step One: Find your API Key</h2>
                <ul>
                  <li>
                    Log into your Loop account and navigate to the{' '}
                    <a href="https://admin.loopreturns.com/settings/developers" target="_blank" rel="noreferrer">
                      Developers
                    </a>{' '}
                    page.
                  </li>
                  <li>
                    Click "Generate API Key" and check the Cart, Order, Return and Report checkboxes. Click the Generate
                    button.
                  </li>
                  <li>Copy the resulting API key to this page and press save.</li>
                </ul>
              </Col>
            </Row>
            {currentStep > 0 && (
              <>
                <Row style={{ margin: '1em 6em' }}>
                  <Col>
                    <h2>Step Two: Set Up Webhooks</h2>
                    <ul>
                      <li id="log-into">
                        Log into your Loop account and navigate to the{' '}
                        <a href="https://admin.loopreturns.com/settings/developers" target="_blank" rel="noreferrer">
                          Developers
                        </a>{' '}
                        page.
                      </li>
                      <li>
                        Enter the following information for the first webhook:
                        <ul>
                          <li>Event: Return created</li>
                          <li>Response: Return</li>
                          <li>
                            URL:{' '}
                            <a
                              href={`https://app.shippingtree.co/api/loop/webhook/${company?.id}/`}
                              onClick={onClickCopy}
                              target="_blank"
                              rel="noreferrer"
                            >
                              https://app.shippingtree.co/api/loop/webhook/{company?.id}/
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li id="info">
                        Enter the following information for the second webhook:
                        <ul>
                          <li>Event: Return updated</li>
                          <li>Response: Return</li>
                          <li>
                            URL:{' '}
                            <a
                              href={`https://app.shippingtree.co/api/loop/webhook/${company?.id}/`}
                              onClick={onClickCopy}
                              target="_blank"
                              rel="noreferrer"
                            >
                              https://app.shippingtree.co/api/loop/webhook/{company?.id}/
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        Finally, for each webhook, click the links in the Event column for "Return created" and "Return
                        updated" and press activate.
                      </li>
                    </ul>
                  </Col>
                </Row>
                <Row style={{ margin: '2em 6em' }}>
                  <Col>
                    <h2>Step Three: Set Up Easypost</h2>
                    It's highly recommended that you set up Easypost with Loop. This way, when a customer returns a
                    product, we can easily identify which order it corresponds to because we have the tracking number.
                    <ul>
                      <li>
                        Sign up for an account at{' '}
                        <a href="https://www.easypost.com/" target="_blank" rel="noreferrer">
                          Easypost
                        </a>
                        .
                      </li>
                      <li>
                        Copy your Production API Key from{' '}
                        <a href="https://www.easypost.com/account/api-keys" target="_blank" rel="noreferrer">
                          this page
                        </a>
                        .
                      </li>
                      <li>
                        Paste this API key into the EasyPost key field in Loop on{' '}
                        <a href="https://admin.loopreturns.com/settings/general" target="_blank" rel="noreferrer">
                          the following page
                        </a>
                        .
                      </li>
                      <li>
                        On the Loop{' '}
                        <a href="https://admin.loopreturns.com/settings/return-policy" target="_blank" rel="noreferrer">
                          Return Policies
                        </a>{' '}
                        page, click Edit on any existing return policy. Turn on the "Easypost labels" toggle and hit
                        save.
                      </li>
                    </ul>
                  </Col>
                </Row>
              </>
            )}
          </GlobalStyles.FullPageCard>
        </>
      ) : null}
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </>
  )
}

import React, { useState, useEffect } from 'react'
import { usePost } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'
import styled from 'styled-components'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { OrderInfo } from './styles'
import { Form } from 'react-bootstrap'
import { HoverNotes } from '../../../components/component-items/hover-notes'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons'
import { faCreditCard, faMoneyCheckAlt } from '@fortawesome/pro-duotone-svg-icons'

import { RechargeSettingsProps } from './types'

const Label = styled(Form.Label)`
  margin-top: 1em;
`

export const RechargeSettings = ({ rechargeAmount, rechargeThreshold, primary, secondary }: RechargeSettingsProps) => {
  const [amount, setAmount] = useState('250')
  const [threshold, setThreshold] = useState('0')
  const {
    state: { csrf },
  } = globalState()

  useEffect(() => {
    setAmount(rechargeAmount)
    setThreshold(rechargeThreshold)
  }, [rechargeAmount, rechargeThreshold])

  return (
    <OrderInfo style={{ display: 'block', height: 'unset' }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>Recharge Settings</h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar></GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div>
        <Form.Group style={{ margin: '1em' }}>
          <Label>
            <HoverNotes
              description="When your balance drops below this amount, we will automatically charge your primary payment method by the recharge amount."
              title="Recharge Threshold"
            />
          </Label>
          <Form.Select
            value={threshold}
            onChange={(e) => usePost(`/api/billing/details/`, { rechargeThreshold: e.target.value }, csrf)}
          >
            <option value="0">$0</option>
            <option value="25">$25</option>
            <option value="50">$50</option>
            <option value="100">$100</option>
            <option value="250">$250</option>
            <option value="500">$500</option>
            <option value="1000">$1000</option>
            <option value="2000">$2000</option>
            <option value="5000">$5000</option>
          </Form.Select>
          <Label>Recharge Amount</Label>
          <Form.Select
            value={amount}
            onChange={(e) => usePost(`/api/billing/details/`, { rechargeAmount: e.target.value }, csrf)}
          >
            <option value="25">$25</option>
            <option value="50">$50</option>
            <option value="100">$100</option>
            <option value="250">$250</option>
            <option value="500">$500</option>
            <option value="1000">$1000</option>
            <option value="2000">$2000</option>
            <option value="5000">$5000</option>
            <option value="10000">$10000</option>
          </Form.Select>
          <Label>Primary Method</Label>
          <div style={{ color: 'black', padding: '0 1em' }}>
            <div style={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}>
              <FontAwesomeIcon
                icon={
                  primary.cardType === 'MC'
                    ? faCcMastercard
                    : primary.cardType === 'VI'
                      ? faCcVisa
                      : primary.ach
                        ? faMoneyCheckAlt
                        : faCreditCard
                }
                style={{ fontSize: '1.5em', marginRight: 5 }}
              />
              {primary.lastDigits ? primary.lastDigits : 'None'}
            </div>
            {primary.ach ? (
              <div>ACH</div>
            ) : (
              <>
                <div>{primary.name}</div>
                <div>{primary.expiry}</div>
              </>
            )}
          </div>
          <Label>Backup Method</Label>
          <div style={{ color: 'black', padding: '0 1em' }}>
            <div style={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}>
              <FontAwesomeIcon
                icon={
                  secondary.cardType === 'MC'
                    ? faCcMastercard
                    : secondary.cardType === 'VI'
                      ? faCcVisa
                      : secondary.ach
                        ? faMoneyCheckAlt
                        : faCreditCard
                }
                style={{ fontSize: '1.5em', marginRight: 5 }}
              />
              {secondary.lastDigits ? secondary.lastDigits : 'None'}
            </div>
            {secondary.ach ? (
              <div>ACH</div>
            ) : (
              <>
                <div>{secondary.name}</div>
                <div>{secondary.expiry}</div>
              </>
            )}
          </div>
        </Form.Group>
      </div>
    </OrderInfo>
  )
}

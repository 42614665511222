import React, { useState, useRef } from 'react'
import { toast } from 'react-toastify'
import { usePost, notify } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { Form, Col, InputGroup, Row } from 'react-bootstrap'
import GenericModal from '../../../components/component-items/modal'
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
import { StatusBoxStyled } from '../../../components/component-items/status-box'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/pro-duotone-svg-icons'

import { ACHModalProps } from './types'

export const AddPaymentMethodModal = ({ open, setOpen, countries }: ACHModalProps) => {
  const node = useRef<HTMLFormElement>(null)
  const {
    state: { csrf },
  } = globalState()

  const [validated, setValidated] = useState(false)
  const [cardData, setCardData] = useState({
    cvc: '',
    expiry_month: '',
    expiry_year: '',
    focus: '',
    name: '',
    number: '',
    issuer: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
    country: 'US',
    phone: '',
  })

  const handleInputFocus = (e: any) => {
    setCardData({ ...cardData, focus: e.target.name })
  }

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setCardData({ ...cardData, [name]: value })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }

    usePost('/api/billing/add_payment_method/', { ...cardData }, csrf)
  }

  return (
    <GenericModal
      heading={'Add Payment Method'}
      show={open}
      size="lg"
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button style={{ minWidth: 125 }} onClick={handleSubmit}>
          Add Card
        </GlobalStyles.Button>
      }
    >
      <div style={{ width: '100%', padding: '2em 4em' }}>
        <Cards
          acceptedCards={['visa', 'mastercard']}
          cvc={cardData.cvc}
          expiry={cardData.expiry_month + cardData.expiry_year}
          focused={cardData.focus}
          name={cardData.name}
          number={cardData.number}
          callback={(e: any) => setCardData({ ...cardData, issuer: e.issuer })}
        />
        <Form noValidate ref={node} validated={validated} onSubmit={handleSubmit} style={{ padding: '2em 0' }}>
          <StatusBoxStyled style={{ margin: 'auto' }} className={'purple'}>
            Please Note: A 3% processing fee is applied to credit card payments. ACH is available without payment fees.
          </StatusBoxStyled>
          <h3 style={{ paddingTop: '1em' }}>Card Details</h3>
          <Row>
            <Form.Group as={Col} md="6" className="required">
              <Form.Label>Cardholder Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Cardholder Name"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter your name exactly as it appears on the card.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" className="required">
              <Form.Label>Card Number</Form.Label>
              <InputGroup>
                <InputGroup.Text id="inputGroupPrepend">
                  <FontAwesomeIcon icon={faCreditCard} />
                </InputGroup.Text>
                <Form.Control
                  type="tel"
                  name="number"
                  placeholder="Card Number"
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Only Mastercard and Visa are accepted methods
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="4" className="required">
              <Form.Label>Expiry Month</Form.Label>
              <Form.Control
                type="number"
                name="expiry_month"
                placeholder="Expiry Month"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                required
              />
              <Form.Control.Feedback type="invalid">Please enter a valid month of expiry.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" className="required">
              <Form.Label>Expiry Year</Form.Label>
              <Form.Control
                type="number"
                name="expiry_year"
                placeholder="Expiry Year"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                required
              />
              <Form.Control.Feedback type="invalid">Please choose a valid expiry year</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" className="required">
              <Form.Label>CVC</Form.Label>
              <Form.Control
                type="number"
                name="cvc"
                placeholder="CVC"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please add the 3 digit CVC number on the back of the card
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <h3 style={{ paddingTop: '1em' }}>Billing Address</h3>
          <Row>
            <Form.Group as={Col} md="6" controlId="validationCustom03" className="required">
              <Form.Label>Address 1</Form.Label>
              <Form.Control
                type="text"
                name="line1"
                placeholder="Address Line 1"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid Address.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom04">
              <Form.Label>Address 2</Form.Label>
              <Form.Control
                type="text"
                name="line2"
                placeholder="Address Line 2"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
              <Form.Control.Feedback type="invalid">Please provide a valid Address.</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="6" controlId="validationCustom03" className="required">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                placeholder="City"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid city.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="validationCustom04" className="required">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                name="state"
                placeholder="State"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid state.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="validationCustom05" className="required">
              <Form.Label>Zip</Form.Label>
              <Form.Control
                type="text"
                name="zip"
                placeholder="Zip"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid zip.</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="6" controlId="validationCustom03" className="required">
              <Form.Label>Country</Form.Label>
              <Form.Select
                name="country"
                defaultValue="US"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                required
              >
                {Object.keys(countries).map((c, id) => (
                  <option value={c} key={id}>
                    {countries[c]}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">Please provide a valid country.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom04" className="required">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="tel"
                name="Phone"
                placeholder="Phone Number"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid phone number.</Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Form>
        <p>
          <em>The card will be automatically charged as charges are incurred. No charges will be made at this time.</em>
        </p>
        <p>
          <em>Please use the two-letter code for your state/province, e.g. California is CA.</em>
        </p>
        <p>
          <em>Only VISA and Mastercard are accepted.</em>
        </p>
      </div>
    </GenericModal>
  )
}

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useFetch, notify } from '../../components/component-items/helpers'
import { globalState } from '../../store'

// Components
import Pager, { Pages } from '../../components/component-items/pager'
import CardLoading from '../../components/component-items/loading-popover'
import GlobalStyles from '../../components/component-items/styles'
import GenericModal from '../../components/component-items/modal'
import { StatusBoxStyled, OrderStatusBox } from '../../components/component-items/status-box'
import { TableCell, THSort } from '../../components/component-items/datatable'
import { AdvancedSelect } from '../../components/component-items/advanced-select'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { TableRow, FilterListItem, DateRangeStyled } from './styles'
import { SearchBox } from '../../components/component-items/search'
import { ButtonLoading } from '../../components/component-items/loading-popover'
import { toast } from 'react-toastify'

// DateRange Component
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { Calendar, DateRangePicker } from 'react-date-range'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarWeek } from '@fortawesome/pro-light-svg-icons'

import { ProductRowProps } from './types'

export const InventoryReportModal = ({ open, setOpen }: { open: boolean; setOpen: (open: boolean) => void }) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ])
  const currentDate = new Date()
  const [snapshotDate, setSnapshotDate] = useState(currentDate)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [sku, setSku] = useState('')
  const [reportType, setReportType] = useState('report')
  const [response, setResponse] = useState('')

  const {
    state: { csrf },
  } = globalState()

  useEffect(() => {
    var startDate = state[0].startDate.toLocaleDateString('en-US')
    var endDate = state[0].endDate.toLocaleDateString('en-US')
    setStartDate(startDate)
    setEndDate(endDate)
    setResponse('')
  }, [state, sku, reportType, snapshotDate])

  async function handleSubmit() {
    const start =
      reportType === 'report'
        ? startDate.replace(/\//g, '-')
        : snapshotDate.toLocaleDateString('en-US').replace(/\//g, '-')
    const end = endDate.replace(/\//g, '-')
    const url =
      reportType === 'report' ? '/api/inventory/inventory-item/report/' : '/api/inventory/inventory-item/snapshot/'
    await fetch(url + '?' + new URLSearchParams({ ...{ sku, start, end } }), {
      method: 'GET',
      headers: {
        'X-CSRFToken': csrf,
      },
    })
      .then((response) => response.json())
      .then(() => {
        setResponse('success')
        notify({ type: 'success', message: 'Report Submitted Successfully' })
      })
      .catch(() => {
        setResponse('error')
      })
  }

  return (
    <GenericModal
      heading={'Inventory Report'}
      show={open}
      size="lg"
      onHide={() => setOpen(false)}
      buttons={
        <ButtonLoading style={{ minWidth: 125 }} onSubmit={() => handleSubmit()} className="primary">
          Download CSV
        </ButtonLoading>
      }
    >
      <div style={{ margin: '1em' }}>
        <FilterListItem>
          <InputGroup style={{ paddingBottom: '2em' }}>
            <Form.Check
              inline
              name="report"
              label="Inventory Report"
              type="radio"
              value="report"
              checked={reportType === 'report'}
              onChange={() => setReportType('report')}
            />
            <Form.Check
              inline
              name="snapshot"
              label="Inventory Snapshot"
              type="radio"
              value="snapshot"
              checked={reportType === 'snapshot'}
              onChange={() => setReportType('snapshot')}
            />
            <StatusBoxStyled className={'purple'} style={{ marginTop: '1em' }}>
              Reports are broken down by date range showing inventory In & Out, whereas snapshots show a complete
              breakdown of lot control for a given date
            </StatusBoxStyled>
          </InputGroup>
          {reportType === 'report' ? (
            <>
              <Form.Label>Date Range</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  value={startDate + ' - ' + endDate}
                  onChange={() => null}
                  onFocus={() => {
                    const startDate = state[0].startDate.toLocaleDateString('en-US')
                    setStartDate(startDate)
                    const endDate = state[0].endDate.toLocaleDateString('en-US')
                    setEndDate(endDate)
                  }}
                  placeholder="Choose A Date"
                  aria-describedby="inputGroupAppend"
                  required
                />
                <InputGroup.Text id="inputGroupAppend">
                  <FontAwesomeIcon icon={faCalendarWeek} />
                </InputGroup.Text>
                <Form.Control.Feedback type="invalid">Please choose a date range to filter by</Form.Control.Feedback>
              </InputGroup>
            </>
          ) : (
            <>
              <Form.Label>Date</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  value={snapshotDate.toLocaleDateString('en-US')}
                  onChange={() => null}
                  placeholder="Choose A Date"
                  aria-describedby="inputGroupAppend"
                  required
                />
                <InputGroup.Text id="inputGroupAppend">
                  <FontAwesomeIcon icon={faCalendarWeek} />
                </InputGroup.Text>
                <Form.Control.Feedback type="invalid">Please choose a date range to filter by</Form.Control.Feedback>
              </InputGroup>
            </>
          )}
        </FilterListItem>
        {reportType === 'report' ? (
          <DateRangeStyled>
            <DateRangePicker
              onChange={(item: any) => setState([item.selection])}
              moveRangeOnFirstSelection={false}
              months={1}
              minDate={new Date(2018, 1, 1)}
              maxDate={currentDate}
              editableDateInputs={true}
              ranges={state}
              direction="horizontal"
              className="hide-in-percy"
            />
          </DateRangeStyled>
        ) : (
          <DateRangeStyled>
            <Calendar onChange={(item) => setSnapshotDate(item)} date={snapshotDate} maxDate={currentDate} />
          </DateRangeStyled>
        )}
        <FilterListItem>
          <Form.Label>SKU (Optional)</Form.Label>
          <Form.Control
            type="text"
            value={sku}
            onChange={(e) => setSku(e.target.value)}
            placeholder="Enter a single non-bundled SKU"
          />
        </FilterListItem>
        <FilterListItem>
          {response && response === 'success' ? (
            <StatusBoxStyled className={'green'}>
              Inventory Report Requested: An email with the results will be sent shortly. View your reports{' '}
              <a href={'/company/reports/'} target="_blank" rel="noreferrer">
                here
              </a>
              .
            </StatusBoxStyled>
          ) : response && response === 'error' ? (
            <StatusBoxStyled className={'red'}>
              An Error Occurred, please contact customer service at:{' '}
              <a href="mailto:support@shippingtree.co">support@shippingtree.co</a>
            </StatusBoxStyled>
          ) : null}
        </FilterListItem>
      </div>
    </GenericModal>
  )
}

export const ProductRow = ({
  id,
  sku,
  img,
  title,
  name,
  qty_on_hand,
  qty_on_order,
  qty_available,
  qty_inbound,
  qty_production,
  qty_defective,
}: ProductRowProps) => {
  return (
    <TableRow>
      <TableCell center>
        <img src={img} style={{ height: 'auto', maxWidth: 100, maxHeight: 50 }} />
      </TableCell>
      <TableCell input={<Link to={`/product/variant/${id}/`}>{name}</Link>}>
        <div>{title}</div>
      </TableCell>
      <TableCell input={sku} />
      <TableCell center input={qty_on_hand} />
      <TableCell center input={qty_on_order} />
      <TableCell center input={qty_defective} />
      <TableCell center input={qty_production} />
      <TableCell
        center
        input={
          <OrderStatusBox
            style={{ width: 100, margin: 'auto' }}
            status={String(qty_available)}
            status_id={qty_available > 0 ? 200 : 0}
          />
        }
      />
      <TableCell center input={qty_inbound} />
    </TableRow>
  )
}

export const ProductList = () => {
  const {
    state: { actAs, userIsWarehouse, productFilterOptions },
    dispatch,
  } = globalState()

  const [page, setPage] = useState(1)
  const tableRowOptions = [20, 50, 100, 250, 500]
  const [rows, setRows] = useState(tableRowOptions[0])
  const [filterOptions, setFilterOptions] = useState(productFilterOptions)
  const [buttonEnabled, setButtonEnabled] = useState(true)

  const setDefaultFilter = () => {
    dispatch({ type: 'productFilterOptions', productFilterOptions: filterOptions })
  }

  // Assign sort based on url params first
  const [sort, setSort] = useState({})
  const [search, setSearch] = useState('')
  const [showModal, setModal] = useState(false)

  const [loading, setLoading] = useState(false)
  const defaultQueryParamsString: any = {
    limit: rows,
    offset: (page - 1) * rows,
    order_by: Object.keys(sort),
    sort: Object.values(sort),
    q: search,
  }
  const url =
    '/api/product/variant/' +
    `?${Object.keys(defaultQueryParamsString)
      .map((key) => `${key}=${defaultQueryParamsString[key]}`)
      .join('&')}` +
    `&${filterOptions
      .filter(({ selected }: any) => selected)
      .map(({ label, value, type }: any) =>
        type === 'boolean' ? label.toLowerCase().split(' ').join('_') + '=' + String(value) : null
      )
      .join('&')}`

  const { response: resp, loaded, error, placeholder }: any = useFetch(url, {})

  const products = loaded ? resp.results : []
  const total = loaded ? resp.count : 0
  const totalPages = loaded && total ? Math.ceil(total / rows) : 1

  useEffect(() => {
    if (loaded && !error) {
      setLoading(!loaded)
    }
  }, [loaded])

  const exportCSV = async () => {
    setButtonEnabled(false)
    const fetchURL = url.replace('/api/product/variant/', '/api/product/variant/csv_list/') + '&no_page=true'
    const response = await fetch(fetchURL)
    if (response.ok) {
      setButtonEnabled(true)
      notify({
        type: 'success',
        message: 'Report submitted successfully. Results will be located in your Reports section.',
      })
    }
  }

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Product List<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          {!userIsWarehouse || actAs ? (
            <>
              <GlobalStyles.Button onClick={() => setModal(true)} style={{ height: 40, width: 170 }}>
                Inventory&nbsp;Report
              </GlobalStyles.Button>
              <InventoryReportModal open={showModal} setOpen={setModal} />
              <GlobalStyles.Button
                style={{ minWidth: 125 }}
                className="secondary"
                disabled={!buttonEnabled}
                onClick={() => exportCSV()}
              >
                Export
              </GlobalStyles.Button>
            </>
          ) : null}
          <SearchBox {...{ search, setSearch, loading, setLoading, page, setPage }} setFocus updateURL />
          <AdvancedSelect placeholder="Filter" {...{ filterOptions, setFilterOptions }} setDefault={setDefaultFilter} />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500 }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th />
              <THSort title={'Product'} {...{ sort, setSort }} />
              <THSort title={'SKU'} {...{ sort, setSort }} />
              <THSort title={'On Hand'} center {...{ sort, setSort }} />
              <THSort title={'On Order'} center {...{ sort, setSort }} />
              <THSort title={'Defective'} sortKey={'quantity_defective'} center {...{ sort, setSort }} />
              <THSort title={'Production'} sortKey={'quantity_production'} center {...{ sort, setSort }} />
              <THSort title={'Available'} sortKey={'quantity_available'} center {...{ sort, setSort }} />
              <THSort title={'Inbound'} sortKey={'quantity_inbound'} center {...{ sort, setSort }} />
            </tr>
          </thead>
          <tbody>
            {products.map((o: any, id: number) => (
              <ProductRow {...o} key={id} />
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      <GlobalStyles.CardFooter>
        <Pages page={page} setPage={setPage} total={totalPages} />
        <Pager options={tableRowOptions} page={page} total={total} rows={rows} setRows={setRows} />
      </GlobalStyles.CardFooter>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}

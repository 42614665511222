import React from 'react'
import { Link } from 'react-router-dom'
import { globalState } from '../../../store'
import { Styled } from './styles'

//Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUsers,
  faTimes,
  faConveyorBeltAlt,
  faGiftCard,
  faTruckContainer,
  faMailBulk,
  faBells,
  faSensorAlert,
  faHandHoldingUsd,
  faFileInvoice,
  faExchange,
  faCalculatorAlt,
  faUsdCircle,
  faCommentAltEdit,
  faFileInvoiceDollar,
  faHistory,
} from '@fortawesome/pro-duotone-svg-icons'
import { faShopify } from '@fortawesome/free-brands-svg-icons'
import { faLoopReturns, faWebhook } from '../../icons'

import { ToolbarItemProps, ToolbarQiuckActionsProps } from './types'

const ToolbarItem = ({ close, url, icon, title }: ToolbarItemProps) => {
  const lineItem = (
    <>
      <div className="item-icon">
        <i>
          <FontAwesomeIcon icon={icon} />
        </i>
      </div>
      <div className="item-title">{title}</div>
    </>
  )
  return url.startsWith('/api') ? (
    <a href={url} className="nav__item">
      {lineItem}
    </a>
  ) : (
    <Link to={url} className="nav__item" onClick={() => close()}>
      {lineItem}
    </Link>
  )
}

export const ToolbarQiuckActions = ({ isOpen, close, node }: ToolbarQiuckActionsProps) => {
  const {
    state: { userIsWarehouse },
  } = globalState()
  return (
    <Styled.QuickACtions>
      <div className={'panel ' + (isOpen ? '' : 'hidden')} ref={node}>
        {userIsWarehouse ? (
          <>
            <div className="panel_head">
              <h3>Warehouse Actions</h3>
              <button onClick={() => close()}>
                <i>
                  <FontAwesomeIcon icon={faTimes} />
                </i>
              </button>
            </div>
            <div className="panel_body">
              <ToolbarItem {...{ close }} url={'/warehouse/users'} icon={faUsers} title={'Warehouse Users'} />
              <ToolbarItem {...{ close }} url={'/postage/reconcile/'} icon={faMailBulk} title={'Postage Reconcile'} />
              <ToolbarItem
                {...{ close }}
                url={'/postage/rate-calculator/'}
                icon={faCalculatorAlt}
                title={'Rate Calculator'}
              />
              <ToolbarItem {...{ close }} url={'/billing/pricing/'} icon={faUsdCircle} title={'Pricing Overview'} />
              <ToolbarItem
                {...{ close }}
                url={'/integration/quickbooks/'}
                icon={faFileInvoiceDollar}
                title={'Quickbooks Integration'}
              />
              <ToolbarItem {...{ close }} url={'/core/tasks/'} icon={faHistory} title={'Async Tasks'} />
              {/* <ToolbarItem url={"/billing/commissions/"} icon={faBadgePercent} title={"Sales Commissions"} /> */}
            </div>
          </>
        ) : null}
        <div className="panel_head">
          <h3>Customer Actions</h3>
          {!userIsWarehouse ? (
            <button onClick={() => close()}>
              <i>
                <FontAwesomeIcon icon={faTimes} />
              </i>
            </button>
          ) : null}
        </div>
        <div className="panel_body">
          <ToolbarItem {...{ close }} url={'/package/rule/'} icon={faConveyorBeltAlt} title={'Packaging Rules'} />
          <ToolbarItem {...{ close }} url={'/product/rule/'} icon={faGiftCard} title={'Promotion Rules'} />
          <ToolbarItem
            {...{ close }}
            url={'/company/packing-slip/'}
            icon={faFileInvoice}
            title={'Packing Slip Settings'}
          />
          <ToolbarItem
            {...{ close }}
            url={'/company/shipping-methods/'}
            icon={faTruckContainer}
            title={'Shipping Methods'}
          />
          <ToolbarItem {...{ close }} url={'/inventory/stock-alerts'} icon={faSensorAlert} title={'Stock Alerts'} />
          <ToolbarItem
            {...{ close }}
            url={'/company/email-notifications/'}
            icon={faBells}
            title={'Email Notifications'}
          />
          <ToolbarItem {...{ close }} url={'/notification/'} icon={faCommentAltEdit} title={'Notifications'} />
          <ToolbarItem {...{ close }} url={'/integration/shop/'} icon={faShopify} title={'Shopify Integration'} />
          <ToolbarItem {...{ close }} url={'/integration/loop/'} icon={faLoopReturns} title={'Loop Settings'} />
          <ToolbarItem {...{ close }} url={'/company/webhooks/'} icon={faWebhook} title={'Webhooks'} />
          <ToolbarItem {...{ close }} url={'/return/returnly/'} icon={faExchange} title={'Returnly Settings'} />
          <ToolbarItem {...{ close }} url={'/company/users/'} icon={faUsers} title={'Merchant Users'} />
          <ToolbarItem {...{ close }} url={'/product/upload-prices/'} icon={faHandHoldingUsd} title={'Update Prices'} />
          {/* <ToolbarItem {...{ close }} url={'/inventory/run-down-rate'} icon={faHeartRate} title={'Run Down Rate'} /> */}
        </div>
      </div>
      <div className={'overlay' + (isOpen ? '' : 'hidden')}></div>
    </Styled.QuickACtions>
  )
}
